import {createReducer} from '../utils';
import {
  SET_USER_DEV_WORKOUTS,
  SET_USER_DEV_WORKOUT,
  GET_USER_DEV_WORKOUTS,
  SET_DEV_WORKOUT_BIG_ASSUMPTION,
  REMOVE_DEV_WORKOUT_BIG_ASSUMPTION,
  SET_DEV_WORKOUT_EXPERIMENT,
  UPDATE_DEV_WORKOUT_BIG_ASSUMPTION,
  ON_API_ERROR,
  SET_DEV_WORKOUT_SESSION,
  SAVE_TOGGLE_DEV_WORKOUT_EXPERIMENT,
  UPDATE_DEV_WORKOUT_EXPERIMENTS,
  SET_UPDATED_DEV_WORKOUT_EXPERIMENT,
} from '../actions/types';

const initialState = {
  workouts: [],
  currentWorkout: null,
};

const devWorkoutReducer = createReducer(initialState, {
  [GET_USER_DEV_WORKOUTS]: (state) => ({...state, loading: false}),
  [SET_USER_DEV_WORKOUTS]: (state, {workouts}) => {
    return {
      ...state,
      workouts,
    };
  },
  [SET_USER_DEV_WORKOUT]: (state, {currentWorkout, isUpdate}) => {
    if (!isUpdate) {
      return {
        ...state,
        currentWorkout: currentWorkout,
      }
    }
    const workouts = state.workouts.map((workout) => {
      const challenges = workout.challenges.map((challenge) => {
        if (challenge._id === currentWorkout._id) {
          return currentWorkout;
        }
  
        return challenge;
      })

      return {...workout, challenges}
    });

    return {
      ...state,
      workouts,
      currentWorkout: currentWorkout,
    };
  },
  [SET_DEV_WORKOUT_SESSION]: (state, {params: {currentSession}}) => {
    return {
      ...state,
      currentWorkout: {
        ...state.currentWorkout,
        currentSession,
      },
    };
  },
  [SET_DEV_WORKOUT_BIG_ASSUMPTION]: (state, {assumptions, entityId}) => {
    return {
      ...state,
      currentWorkout: {
        ...state.currentWorkout,
        bigAssumptions: assumptions
      },
    };
  },
  [SET_DEV_WORKOUT_EXPERIMENT]: (state, {bigAssumption}) => {
    const bigAssumptions = state.currentWorkout.bigAssumptions.map(assumption => {
      if (assumption._id === bigAssumption._id) {
        return bigAssumption;
      }

      return assumption;
    });

    return {
      ...state,
      currentWorkout: {
        ...state.currentWorkout,
        bigAssumptions,
      },
    };
  },
  [SET_UPDATED_DEV_WORKOUT_EXPERIMENT]: (state, {bigAssumptionId, experiment, entityId}) => {
    const bigAssumptions = state.currentWorkout.bigAssumptions.map(assumption => {
      if (assumption._id === bigAssumptionId) {
        assumption.experiments.map((exp) => {
          if(exp._id === experiment._id) {
            exp.isDeleted = experiment.isDeleted;
            exp.isHidden = experiment.isHidden;
          }
          return exp;
        })
      }

      return assumption;
    });

    return {
      ...state,
      currentWorkout: {
        ...state.currentWorkout,
        bigAssumptions,
      },
    };
  },
  [SAVE_TOGGLE_DEV_WORKOUT_EXPERIMENT]: (state, {bigAssumptionId, experimentId, isHidden, entityId}) => {
    const bigAssumptions = state.currentWorkout.bigAssumptions;

    bigAssumptions.forEach(assumption => {
      if (assumption._id === bigAssumptionId) {
        assumption.experiments.forEach(experiment => {
          if(experiment._id === experimentId) {
            experiment.isHidden = isHidden;
          }
        })
      }
    });

    return {
      ...state,
      currentWorkout: {
        ...state.currentWorkout,
        bigAssumptions,
      },
    };
  },

  [UPDATE_DEV_WORKOUT_EXPERIMENTS]: (state, {experiment, entityId}) => {
    const bigAssumptions = state.currentWorkout.bigAssumptions;
    const {bigAssumption} = experiment;

    bigAssumptions.forEach(assumption => {
      if (assumption._id === bigAssumption) {
        assumption.experiments = [...assumption.experiments, experiment];
      }

      return assumption
    });

    return {
      ...state,
      currentWorkout: {
        ...state.currentWorkout,
        bigAssumptions,
      },
    };
  },

  [REMOVE_DEV_WORKOUT_BIG_ASSUMPTION]: (state, {bigAssumptionId, entityId}) => {
    const workout = state.currentWorkout;
    const bigAssumptions = workout.bigAssumptions.filter(({_id}) => _id !== bigAssumptionId);

    return {
      ...state,
      currentWorkout: {
        ...state.currentWorkout,
        bigAssumptions,
      },
    };
  },
  [UPDATE_DEV_WORKOUT_BIG_ASSUMPTION]: (state, {assumptionId, field, value, entityId}) => {
    const bigAssumptions = state.currentWorkout.bigAssumptions;
    const updatedBigAssumptions = bigAssumptions.map(assumption => {
      if (assumption._id === assumptionId) {
        return {
          ...assumption,
          [field]: value
        };
      }

      return assumption;
    });

    return {
      ...state,
      currentWorkout: {
        ...state.currentWorkout,
        bigAssumptions: updatedBigAssumptions
      },
    };
  },
  [ON_API_ERROR]: () => initialState
});

export default devWorkoutReducer;
